const defaultState = {
  loading: false,
  labels: {
    "Loan Account": "",
    "Loan Number": "",
    "Borrowers": "",
    "Borrower’s Chat": "",
    "Export Borrowers list": "",
    "ACCOUNT NUMBER" : "",
    "Link Accounts" : "",
    "Lender Chat" : "",
    "Account Details" : "",
    "Last Payment" : "",
    "Next Payment" : "",
    "Total Payable" : "",
    "No scheduled payments" : "",
    "LINK ACCOUNT" : "Link Account",
    "Social Security Number" : "",
    "Social Security Number Four" : "",
    "Branch Number" : "",
    "DOB" : "DOB",
    "Postal Code" : "",
    "Phone Number" : "Phone Number",
    "First Name" : "First Name",
    "Payment Options" : "Payment Options",
    "Change Password" : "Change Password",
    "Contact Information" : "Contact Information",
    "schedule a payment" : "Schedule a Payment",
    "Schedule Date" : "Schedule Date",
    "Schedule Date 2" : "Schedule Date 2",
    "Payment Frequency" : "Payment Frequency",
    "SORT BY" : "SORT BY",
    "CARD" : "CARD",
    "CHECK" : "CHECK",
    "Payment Type" : "Payment Type",
    "Street Number and Name" : "Street Number and Name",
    "Unit Number" : "Unit Number",
    "State" : "State",
    "City" : "City",
    "Zipcode" : "Zipcode",
    "EMAIL ADDRESSES" : "Email Addresses",
    "Add Card Title" : "Add Card",
    "Add Card Description" : "Please provide the information below to make payments using your bank card.",
    "Add Bank Account Title" : "Add Bank Account",
    "Add Bank Account Description" : "Please provide the information below to make payments using your bank account.",
    "Add Bank Account (CA) Title" : "Add Bank Account (CA)",
    "Add Bank Account (CA) Description" : "Please provide the information below to make payments using your bank account.",
    "Routing (ABA) Number" : "Routing (ABA) Number",
    "Full Name" : "Full Name",
    "Account Type" : "Account Type",
    "Bank Nickname" : "Bank Nickname",
    "Billing Zip" : "Billing Zip",
    "CVC" : "CVC",
    "Expiration Date" : "Expiration Date",
    "Card Number" : "Card Number",
    "Name" : "Name",
    "Bank Account (CA)" : "Bank Account (CA)",
    "Bank Account" : "Bank Account",
    "Bank Account Number" : "Bank Account Number",
    "Pay" : "Pay",
    "Add New Payment Method": "Add New Payment Method",
    "Status" : "STATUS",
    "Receipt Title" : "Congratulations",
    "Receipt Message" : "Your payment has been received",
    "Auth Code" : "AUTH CODE",
    "Confirmation Number" : "CONFIRMATION #",
    "Mobile Number" : "Mobile Number",
    "Remove Recurring All Schedules" : "Remove Recurring All Schedules",
    "Email Address" : "Email Address",
    "Convenience Fee": "Convenience Fee",
    "Convenience Fee Details": "Convenience Fee Details",
    "AccountNumTip": "Enter your bank account number",
    "RoutingNumTip": "Enter your bank routing number",
    "Phone Numbers" : "Phone Numbers",
    "Phone Email Instructions" : "Primary phone numbers and email addresses cannot be deleted - please assign a new one before deleting",
    "Only 3 Phone Numbers" : "Three phone numbers are the limit, you can replace one of the previously entered",
    "Sign In": "Sign In",
    "Don't have an account": "Don't have an account?",
    "Sign Up": "Sign Up",
    "Remove account confirmation": "Are you sure you want to delete this loan?",
    "Save": "Save",
    "Cancel": "Cancel",
    "Confirm Delete": "Yes, Delete",
    "Try Again": "Try Again",
    "Notify Lender": "Notify Lender",
    "Back to Home": "Back to Home",
    "Upload": "Upload",
    "Close": "Close",
    "Confirm Remove": "Yes, Remove",
    "Resend Link": "Resend Link",
    "Submit": "Submit",
    "Proceed": "Proceed",
    "Send Receipt": "Send Receipt",
    "Save Changes": "Save Changes",
    "Edit": "Edit",
    "Send": "Send",
    "Confirm": "Confirm",
    "Verify": "Verify",
    "Mob Sign Up with email": "Sign Up with email",
    "Mob I already have an account": "I already have an account",
    "PRINCIPAL": "PRINCIPAL",
    "INTEREST": "INTEREST",
    "LATE FEE": "LATE FEE",
    "NSF FEE": "NSF FEE",
    "PRINCIPAL BALANCE": "PRINCIPAL BALANCE",
    "Next": "Next",
    "Payment receipts will be sent" : "Payment receipts will be sent to this email address",
    "If you would like your payment receipts emailed": "If you would like your payment receipts emailed to a different address please enter it below.",
    "Terms And Conditions Acceptance": "I accept the terms and conditions & TCPA",
    "Email Address/ Mobile Number": "Email Address/ Mobile Number",
    "Password": "Password",
    "Delete Loan": "Unlink",
    "ONE TIME": "One Time",
    "Have an account": "Have an account?",
    "MONTHLY": "Monthly",
    "Please change the fields below to change the billing address for your account.": "Changing this address will not change the billing address for your account.",
    "UPDATE YOUR ADDRESS": "Update Your Address",
    "past_due_pmt_date": "Total Amount Due",
    "current_pmt_date": "Next Payment",
    "RECEIVE A CALL": "/ Receive a call",
    "Link Account Issue message": "We couldn’t link your account.<br />Would you like to try again or notify an agent?",
    "Notify agent Linking Account Issue button": "Notify Agent",
    "Linking Account Issue confirmation message line1": "A notice has been sent to an agent to assist with linking your account.",
    "Linking Account Issue confirmation message line2": "An agent will contact you.",
    "Select Language": "Select Language",
    "Change Language" : "Change Language",
    "Forgot Password" : "Forgot Password",
    "Forgot Password Text" : "Enter your email address or mobile number below and we'll send you a link to reset your password.",
    "Home" : "Home",
    "Receipts" : "Receipts",
    "Schedule" : "Schedule",
    "Contact Us" : "Contact Us",
    "Settings" : "Settings",
    "Logout" : "Logout",
    "Postal code" : "Postal code",
    "State" : "State",
    "City" : "City",
    "Unit Number" : "Unit Number",  
    "Street Number and Name" : "Street Number and Name",
    "Forgot Your Password" : "Forgot Your Password?", 
    "Add your Loan Account" : "Add your Loan Account",
    "Congratulations" : "Congratulations",
    "Your payment has been scheduled" : "Your payment has been scheduled",
    "Are you sure you want to delete this recurring schedule?" : "Are you sure you want to delete this recurring schedule?",
    "Are you sure you want to delete scheduled payment?" : "Are you sure you want to delete scheduled payment?",
    "Regular Payment" : "Regular Payment",
    "Enter amount you would like to pay" : "Enter amount you would like to pay",
    "Submitting this will overwrite the amount and payment method on all schedules in Group:" : "Submitting this will overwrite the amount and payment method on all schedules in Group:",
    "Edit Recurring Schedule" : "Edit Recurring Schedule",
    "Payment Amount:" : "Payment Amount:",
    "Payment Start Date:" : "Payment Start Date:",
    "Bank Account:" : "Bank Account:",
    "Card Number:" : "Card Number:",
    "Edit Schedule Payment" : "Edit Schedule Payment",
    "Regular Payment" : "Regular Payment",
    "Enter amount you would like to pay" : "Enter amount you would like to pay",
    "Old Password" : "Old Password",
    "New Password" : "New Password",
    "Confirm New Password"  : "Confirm New Password",
    "Remove Email" : "Remove Email",
    "Make Primary Email" : "Make Primary Email",
    "Make Primary Phone" : "Make Primary Phone",
    "Remove phone" : "Remove phone",
    "Select Account" : "Select Account",
    "Add Email Address" : "Add Email Address",
    "Add Mobile Phone" : "Add Mobile Phone",
    "SELECT PAYMENT METHOD" : "SELECT PAYMENT METHOD",
    "LEARN MORE": "LEARN MORE",
    "Call to Edit" : "Call to Edit",
    "Edit One-Time Payment" : "Edit One-Time Payment",
    "Delete Recurring Schedule" : "Delete Recurring Schedule",
    "Delete One-Time Payment" : "Delete One-Time Payment",
    "Edit Recurring Schedule" : "Edit Recurring Schedule",
    "Edit One-Time Payment" : "Edit One-Time Payment",
    "Edit Recurring Schedule" : "Edit Recurring Schedule",
    "Delete Recurring Schedule" : "Delete Recurring Schedule",
    "Delete One-Time Schedule" : "Delete One-Time Schedule",
    "Remove Account" : "Remove Account",
    "Terms & Conditions" : "Terms & Conditions",
    "View privacy policy" : "View privacy policy",
    "Email / SMS Receipt" : "Email / SMS Receipt",
    "Enter recipient email address or mobile phone number below": "Enter recipient email address or mobile phone number below and we’ll send them a payment receipt.",
    "REFUNDED AMT" : "REFUNDED AMT",
    "Frequency" : "Frequency",
    "Number of Recurring Payments:" : "Number of Recurring Payments:",
    "Payment Date:" : "Payment Date:",
    "Privacy Policy" : "Privacy Policy",
    "CANCEL" : "CANCEL",
    "SUBMIT" : "SUBMIT",
    "Bank Number" : "Bank Number",
    "Month" : "Month",
    "Year" : "Year",
    "Savings" : "Savings",
    "Checking" : "Checking",
    "No payment receipts" : "No payment receipts.",
    "Payment Date Ascending" : "Payment Date Ascending",
    "Payment Date Descending" : "Payment Date Descending",
    "Payment Amount Ascending" : "Payment Amount Ascending",
    "Payment Amount Descending" : "Payment Amount Descending",
    "Write message" : "Write message...",
    "Are you sure you want to logout?" : "Are you sure you want to logout ?",
    "Are you sure you want to delete all the scheduled payments?" : "Are you sure you want to delete all the scheduled payments?",
    "Are you sure you want to remove this payment method?" : "Are you sure you want to remove this payment method?",
    "Remove payment method linked schedule" : "This payment method is linked with your scheduled payment.  Deleting this payment method will void your scheduled payment.",
    "Add" : "Add",
    "Please enter the information below to access your account" : "Please enter the information below to access your account",
    "Scheduled" : "Scheduled",
    "Deleted" : "Deleted",
    "Declined" : "Declined",
    "All" : "All",
    "seconds" : "seconds.",
    "Resend code will enable in" : "Resend code will enable in",
    "Resend Code?" : "Resend Code?",
    "Select" : "Select",
    "Do you want to resend verification link?" : "Do you want to resend verification link?",
    "Email Address is not verified!" : "Email Address is not verified!",
    "Website:" : "Website:",
    "Phone:" : "Phone:",
    "Email:" : "Email:",
    "Preview" : "Preview",
    "Choose an image" : "Choose an image",
    "Image Upload" : "Image Upload",
    "DATE" : "DATE",
    'This field is required!' : 'This field is required!',
    'minimum length should be 9 digits' : 'minimum length should be 9 digits',
    'It should be between 4 to 25 characters' : 'It should be between 4 to 25 characters',
    'CVC should be 3 numbers.' : 'CVC should be 3 or 4 numbers.',
    'Zip code should be 5 numbers' : 'Zip code should be 5 numbers',
    'Account Number should be between 4 to 25 characters' : 'Account Number should be between 4 to 25 characters',
    'Branch Number should be 5 characters' : 'Branch Number should be 5 characters',
    'Add Email Address' : 'Add Email Address',
    'Email Address is not verified!' : 'Email Address is not verified!',
    'Do you want to resend verification link?' : 'Do you want to resend verification link?',
    'Phone Number' : 'Phone Number', 
    'Resend code will enable in' : 'Resend code will enable in',
    'Edit' : 'Edit',
    'This must be a valid e-mail' : 'This must be a valid e-mail',
    'Please, select a State/Province' : 'Please, select a State/Province',
    'Please, select a Language' : 'Please, select a Language',
    'Password and password confirmation must match' : 'Password and password confirmation must match',
    'Must contain one lower & uppercase character, one number, one special &  must be 8-14 characters long' : 'Must contain one lower & uppercase character, one number, one special &  must be 8-14 characters long',
    'Select' : 'Select',
    'Postal code cannot exceed 7 characters.' : 'Postal code cannot exceed 7 characters.',
    'Please enter the information below to access your account' : 'Please enter the information below to access your account',
    'DATE' : 'DATE',
    'Must contain one lower & uppercase character, one number, one special & must be 8-50 characters long' : 'Must contain one lower & uppercase character, one number, one special & must be 8-50 characters long',
    'Image Upload' : 'Image Upload',
    'Choose an image' : 'Choose an image',
    'Preview' : 'Preview',
    'Confirm Password' : 'Confirm Password',
    'Last 4 SSN' : 'Last 4 SSN',
    'DOB (optional)' : 'DOB (optional)',
    'Last Name' : 'Last Name',
    'Expiry' : 'Expiry',
    'Routing #' : 'Routing #',
    'Bank #' : 'Bank #',
    '(Checking)' : '(Checking)',
    '(Saving)' : '(Saving)',
    'Account holder Name' : 'Account holder Name',
    'Verify Phone Number' : 'Verify Phone Number',
    'Account Min Length Msg' : 'Please enter last six digits of Account Number including dash',
    'Only Once' : 'Only Once',
    'Monthly' : 'Monthly',
    'Bi Weekly' : 'Bi Weekly',
    'Payment Frequency' : 'Payment Frequency',
    'Email/SMS' : 'Email/SMS',
    'Group:' : 'Group:',
    'Recurring' : 'Recurring',
    'Twice a month' : 'Twice a month',
    'Weekly' : 'Weekly',
    'Today' : 'Today',
    'Load more messages' : 'Load more messages',
    'previous' : 'Previous',
    'next' : 'Next',
    'PAID' : 'PAID',
    'Voided' : 'Voided',
    'Refunded' : 'Refunded',
    'FINAL SCHEDULE' : 'FINAL SCHEDULE',
    'Copyright Text' : 'Copyright @ Payix Inc',
    'SCHEDULED' : 'SCHEDULED',
    'WEB' : 'WEB',
    'MOB' : 'MOB',
    'IVR' : 'IVR',
    'TEL' : 'TEL',
    'SMS' : 'SMS',
    'Android' : 'Android',
    'Ios' : 'Ios',
    'Visa' : 'Visa',
    'Mastercard' : 'Mastercard',
    'Discover' : 'Discover',
    'American Express' : 'American Express',
    'Every two weeks' : 'Every two weeks',
    'payments' : 'payments',
    'Session Timeout Title' : 'Your session is about to expire, click continue to extend your session',
    'Session Timeout Message' : 'Session Timeout',
    'Some field should be filled' : 'Some field should be filled',
    'Phone number added' : 'Phone number added',
    'Email address added' : 'Email address added',
    'Continue' : 'Continue',
    'DECLINED' :'DECLINED',
    'DELETED' :'DELETED',
    'CHECK NUMBER' : 'CHECK NUMBER',
    'Loading' : 'Loading...',
    'Add Phone Number' : 'Add Phone Number',
    "ROUTING NUMBER" : "ROUTING NUMBER",
    "ACCOUNT NUMBER" : "ACCOUNT NUMBER",
    "CHECK NUMBER" : "CHECK NUMBER",
    "Message Scheduled Payment": "You already have a payment scheduled. Do you want to continue?",
    "Payment Message Text": "Online payment do not replace previously setup automatic payments. If you are an EasyPay customer and would like to cancel your automatic payments of if you would like to sign up for automatic payments, please call us at (866) 343-4345.",
    "Message Payment Button": "To schedule future dated payments, click Schedule",
    "Message Scheduled Payment Button": "When creating monthly recurring schedules, the payment method must be an ACH Bank Account",
    "Due Date": "Due Date",
    "Scheduled loan delete confirmation message": "This loan is already linked with a scheduled payment. Are you sure you want to delete it ?",
    "You already have a payment scheduled. Do you want to continue?": "You already have a payment scheduled. Do you want to continue?",
    "Statements": "Statements",
    "Documents": "Documents",
    "Statements and Documents": "Statements and Documents",
    "Scheduled Date": "Scheduled Date",
    "Next Execution Date": "Next Execution Date",
    "Recurring Group Id": "Recurring Group Id",
    "Payment Amount": "Payment Amount",
    "Recurring Type": "Recurring Type",
    "View Document": "View Document",
    "N/A": "N/A",
    "Not available": "Not available",
    "View": "View",
    "No Records found": "No Records found",
    "Go to page": "Go to page",
    "Select Account": "Select Account",
    "Select Action": "Select Action",
    "Email already saved": "You have already registered this email",
    "Change email address?": "You need to verify your email before you login. If you would like the verification link emailed to a different address please enter it below",
    "Keep email": "Keep email",
    "Replace email": "Replace email",
    "The email address is invalid": "The email address is invalid",
    "This email address is the same as the previous": "This email address is the same as the previous",
    "Invalid email or phone number": "Invalid email or phone number",
    "Select Account": "Select Account",
    "View": "View",
    "No Records found": "No Records found",
    "Statement Date": "Statement Date",
    "Date Paid": "Date Paid",
    "Past Due Balance": "Past Due Balance",
    "Current Balance": "Current Balance",
    "Current Payoff": "Current Payoff",
    "View Statements": "View Statements",
    "Go to page": "Go to page",
    "NOTE:" : "NOTE:",
    "One Time Ach Import Nls Disclaimer Note" : "ACH payments will not display a receipt for one to two business days",
    "Please enter a valid email address": "Please enter a valid email address",
    "Delete": "Delete",
    "AutoPay": "AutoPay",
    "Primary": "Primary",
    "Amount should be at least $:amount" : "Amount should be at least $:amount",
    "Servicing": "Servicing",
    "Enable Popup Windows Statements": "Enable pop-up windows to view statements",
    "More Details Disclaimer": "Transaction details might take up to 10 minutes to update after a payment is made",
    "Invalid Phone Number" : "Please enter a valid phone number",
    "Please enter the last four of your Social Security Number": "Please enter the last four of your Social Security Number",
    "Please enter your full Social Security Number": "Please enter your full Social Security Number",
    "Phone number minimum length should be 10 digits": "Phone number minimum length should be 10 digits",
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'loading-config':
      return { ...state, loading: true }
    case 'loading-config-finish':
      var mergeState = {...state.labels, ...action.payload};
      return { ...state, labels: mergeState, loading: false }
    case 'loading-config-error':
      return { ...state, error: action.error }
    default:
      return state;
  }
};
