import React, { Component } from 'react';
import { Application } from '../core/app';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getReceipts as requestGetReceipts } from '../api/payment';
import { withRouter } from 'react-router-dom';
import { toUSD } from '../helpers/formatters';

// Import actions
import { showLoading, hideLoading, sendReceipt } from '../actions/app';
import { fetchLoanAccounts } from '../actions/loan-account';
import { fetchPaymentCards } from '../actions/client';

// Import components
import LoanNumber from '../components/application/page-options/loan-number';
import Modal from '../components/application/modal';
import Paginator from '../components/application/paginator';
import PaymentReceipt from '../components/payment/receipt';
import PaymentReceiptDelivery from '../components/receipts/payment-receipt-delivery';
import PaymentTypes from '../components/application/page-options/payment-types';
import SortBy from '../components/application/page-options/sort-by';

// Import styles
import '../styles/modules/receipts/index.css';
import '../styles/layout/receipts.css';
class Receipts extends Component {
  state = {
    isSendReceiptModalOpen: false,
    receipts: [],
    loanAccounts: [],
    loanNo: '',
    page: 0,
    paymentType: null,
    sortBy: 2,
    totalPages: 0,
    totalReceipts: 0
  };


  limit = 100;
  selectedReceipt = null;

  /**
   * Fetch the initial receipts.
   */
  componentDidMount() {
    this.fetchInitialData();
  }

  /**
   * Fetch the loan accounts and the payment methods.
   */
  fetchInitialData = () => {
    this.props.showLoading();

    Promise.all([
      this.props.fetchLoanAccounts(),
      this.props.fetchPaymentCards()
    ]).then(() => {
      this.props.hideLoading();
      let loanAccounts = this.props.loanAccounts.map(
        loanAccount => loanAccount.loanNo
      );

      this.setState(
        {
          loanAccounts: loanAccounts,
          loanNo: loanAccounts.join(',')
        }, () => {
          this.fetchReceipts(this.state.page);
        }
      );
    })
    .catch(() => {
      Application.showInternalError();
    });
  };

  /**
   * Display or hidden a specific modal.
   *
   * @param modalId
   */
  toggleModal(modalId) {
    this.setState(prevState => {
      let key = `is${modalId}ModalOpen`;
      let newState = {};

      newState[key] = !prevState[key];

      return newState;
    });
  }

  /**
   * Get the information of the payment method used for a specific receipt.
   *
   * @param {*} receipt
   */
  getPaymentMethod(receipt) {
    let paymentMethod = null;

    if (receipt.paymentsMade) {
      if (receipt.paymentsMade.paymentType === 1) {
        paymentMethod = receipt.paymentsMade.cardMethod;
      } else if (receipt.paymentsMade.paymentType === 2) {
        paymentMethod = receipt.paymentsMade.achMethod;
      } else {
        paymentMethod = receipt.paymentsMade.padMethod;
      }
    }

    return paymentMethod;
  }

  /**
   * Get the payment auth code.
   *
   * @param {*} receipt
   */
  getAuthCode(receipt) {
    if (receipt.paymentsMade) {
      return receipt.paymentsMade.authId;
    }

    return 'N/A';
  }

  /**
   * Get the payment confirmation No.
   *
   * @param {*} receipt
   */
  getConfirmationNo(receipt) {
    if (receipt.paymentsMade) {
      // Confirmation number is stored as orderId
      return receipt.paymentsMade.orderId;
    }

    return 'N/A';
  }

  /**
   * Get Refunded Amount.
   *
   * @param {*} receipt
   */
  getRefundedAmt(receipt) {
    if (receipt.paymentsMade && receipt.paymentsMade.refundedAmt != 0) {
      return toUSD(receipt.paymentsMade.refundedAmt);
    }

    return '0';
  }
  
  /**
   * Get Convenience Amount.
   *
   * @param {*} receipt
   */
  getConvenienceAmt(receipt) {
    
    if ((receipt.paymentsMade && receipt.paymentsMade.convenienceFee)
      || (receipt.paymentsMade && receipt.paymentsMade.cardTransactionAmount)
      || (receipt.paymentsMade && receipt.paymentsMade.achTransactionAmount)
      ) {
      return <div className="receipt__data-section">
          <p className="receipt__value">{toUSD(receipt.paymentsMade.convenienceFee + receipt.paymentsMade.cardTransactionAmount + receipt.paymentsMade.achTransactionAmount)}</p>
          <span className="receipt__label">{this.props.whiteLabel.labels['Convenience Fee']}</span>
        </div>;
    } 

    return <div className="receipt__data-section"><br></br><br></br></div>;
  }
  

  /**
   * Get the platform where the payment was made.
   *
   * @param {*} receipt
   */
  getPlatform(receipt) {
    if (receipt.paymentsMade) {
      if (receipt.paymentsMade.platform) {
        return receipt.paymentsMade.platform.platformType;
      }

      return '';
    }

    return 'LMS';
  }

  /**
   * Get the options for setup the receipt component.
   *
   * @param {*} receipt
   */
  setOptions(receipt) {
    if (!receipt.paymentsMade) {
      return {};
    }

    return {
      orderId: receipt.paymentsMade.orderId,
      onSend: this.handleSendOption
    };
  }

  /**
   * Make a request to get the receipts.
   *
   * @param {number} page - The page number.
   */
  fetchReceipts(page) {
    this.props.showLoading();

    /**
     * The page handles the indexes of the pages starting from 0,
     * but the api from 1 is why whenever we request a page to the api we add one.
     */
    page += 1;

    let data = {
      limit: this.limit,
      sortBy: this.state.sortBy,
      loanNo: this.state.loanNo,
      page
    };

    // Append modeOfPayment if payment type has some value.
    if (this.state.paymentType) {
      data.modeOfPayment = this.state.paymentType;
    }else{
      data.modeOfPayment = this.props.countryCode === 'CA' ? this.props.enableCardCanada ? 4 : 3 : -1
    }

    requestGetReceipts(data)
      .then(response => {
        let dataResponse = response.data;

        this.setState({
          receipts: dataResponse.result.receipts,
          page: parseInt(dataResponse.result.currentPage, 10) - 1,
          totalPages: dataResponse.result.totalPage,
          totalReceipts: dataResponse.numberOfElements
        });

        this.props.hideLoading();
      })
      .catch(() => {
        this.props.hideLoading();
      });
  }

  /**
   * Handle the event to request the new page the receipts requested by the user.
   */
  handleChangePage = data => {
    let page = data.selected;
    this.fetchReceipts(page);
  };

  /**
   * Handle the event to select or deselect an account for get receipts.
   *
   * @param {array} selectedAccounts - An array with the selected loan accounts number.
   */
  handleSelectLoanAccount = selectedAccounts => {
    let loanNo = selectedAccounts.join(',');

    // Solo se puede realizar la consulta de recibos si hay al menos un cuenta selecciona y un método de pago.
    if (loanNo !== '' && this.state.paymentType !== -1) {
      this.setState({ loanNo }, () => {
        this.fetchReceipts(0);
      });
    } else {
      this.setState({
        loanNo: loanNo,
        receipts: []
      });
    }
  };

  /**
   * Handle the event to select payment type.
   */
  handleSelectPaymentType = values => {
    let newState = {
      paymentType: -1
    };

    if (values.check && values.pad && !values.card) {
      newState.paymentType = 5;
    } else if (!values.check && values.pad && values.card) {
      newState.paymentType = 4;
    } else if (values.pad && !values.check && !values.card) {
      newState.paymentType = 3;
    } else if (values.check && !values.card && !values.pad) {
      newState.paymentType = 2;
    } else if (!values.check && !values.pad && values.card) {
      newState.paymentType = 1;
    }  else if (values.check && values.card && values.pad) {
      newState.paymentType = null;
    }

    // If no method of payment was chosen we do not make any request,
    // we only delete the entire array of receipts.
    if (newState.paymentType !== -1 && this.state.loanNo !== '') {
      this.setState(newState, () => {
        this.fetchReceipts(0);
      });
    } else {
      this.setState({
        paymentType: newState.paymentType,
        receipts: []
      });
    }
  };

  /**
   * Handle the event to select a sort by option.
   */
  handleSelectSortBy = e => {
    let sortBy = e.target.value;

    this.setState({ sortBy }, () => {
      this.fetchReceipts(0);
    });
  };

  /**
   * Show a modal with form to send a receipt via e-mail or phone.
   *
   * @param {*} id The receipt id.
   */
  handleSendOption = id => {
    this.selectedReceipt = id;
    this.toggleModal('SendReceipt');
  };

  /**
   * Handle the send receipt event.
   *
   * @param {*} id The receipt id.
   */
  handleSendPaymentReceipt = values => {
    this.props.sendReceipt(values)
      .then((response) => {
        if (response) {
          this.toggleModal('SendReceipt');
        }
      });
  };

  /**
   * Renders a message indicating that there are no payment receipts.
   */
  renderEmptyReceipts() {
    return (
      <div className="empty-message">
        <i className="fas fa-info-circle empty-message__icon" />
        <p className="empty-message__text">{this.props.whiteLabel.labels['No payment receipts']}</p>
      </div>
    );
  }

  renderLeftContent = receipt => {
    let paymentMethod = this.getPaymentMethod(receipt);
    let loanAccount = receipt.loan;
    let collateral = loanAccount.loanCollateral;
    let brand = null;
    let lastFour = null;

    if (paymentMethod) {
      // We suposse that the payment method is card default, else override the info.
      brand = paymentMethod.cardBrand;
      lastFour = paymentMethod.lastFour;

      if (paymentMethod.paymentType === 2) { // Account bank
        brand = paymentMethod.bankName;
        lastFour = paymentMethod.lastFour;
      } else if (paymentMethod.paymentType === 3) {
        brand = paymentMethod.bankName;
        lastFour = paymentMethod.lastFour;
      }
    }

    return <React.Fragment>

      {this.getConvenienceAmt(receipt)}
      <div className="receipt__data-section">
        <p className="receipt__value">{(this.getAuthCode(receipt) !== 'N/A') ? this.getAuthCode(receipt) : ((receipt.paymentMethodReferenceNo !== null && receipt.paymentMethodReferenceNo !== '')? receipt.paymentMethodReferenceNo : 'N/A')}</p>
        <span className="receipt__label">{this.props.whiteLabel.labels['Auth Code']}</span>
      </div>
      <div className="receipt__data-section">
        <p className="receipt__value">{(this.getConfirmationNo(receipt) !== 'N/A') ? this.getConfirmationNo(receipt) : ((receipt.paymentReferenceNo !== null && receipt.paymentReferenceNo !== '')? receipt.paymentReferenceNo : 'N/A')}</p>
        <span className="receipt__label">{this.props.whiteLabel.labels['Confirmation Number']}</span>
      </div>

       <div className="receipt__data-section">
        <p className="receipt__value">
          {(collateral.collatYear !== '' && collateral.collatMake !== '') 
            ? `${collateral.collatYear} ${collateral.collatMake}`
            : ''
          }
        </p>
        <span className="receipt__label">
          {(collateral.collatModel !== '') 
            ? loanAccount.loanCollateral.collatModel
            : ''
          }
        </span>
      </div>

      <div className="receipt__data-section">
        <p className="receipt__value">{loanAccount.loanNo}</p>
        <span className="receipt__label">{this.props.whiteLabel.labels['Loan Number']}</span>
      </div>

      {paymentMethod ? (
        <div className="receipt__data-section">
          <p className="receipt__value" title={brand}>{brand}</p>
          <span className="receipt__label">**{lastFour}</span>
        </div>
      ) : (
        <div className="receipt__data-section receipt__data-section--error">
          <p> </p>
          <span> </span>
        </div>
      )}
    </React.Fragment>;
  };

  /**
   * Render the component view.
   */
  render() {
    return (
      <div>
        {/**
         ** Options & Filters
         **/}
        <div className="page-options">
          {this.state.loanAccounts.length > 0 && (
            <div className="page-options__content">
              <LoanNumber
                whiteLabel={this.props.whiteLabel}
                loanAccounts={this.state.loanAccounts}
                onSelectedItem={this.handleSelectLoanAccount}
              />
              <PaymentTypes
                onSelectedPaymentType={this.handleSelectPaymentType}
              />
              <div className="lmargin-auto">
                <SortBy
                  whiteLabel={this.props.whiteLabel}
                  selected={this.state.sortBy}
                  onChange={this.handleSelectSortBy}
                />
              </div>
            </div>
          )}
        </div>

        {/**
         ** Content
         **/}
        {this.state.receipts.length > 0 ? (
          <div className="content">
            <div className="app-receipts__list">
              {this.state.receipts.map(receipt => (
                <div className="app-receipts__item" key={receipt.id}>
                  <PaymentReceipt
                    platform={this.getPlatform(receipt)}
                    date={receipt.datePaid}
                    leftContent={this.renderLeftContent(receipt)}
                    achStatus={receipt.paymentsMade ? receipt.paymentsMade.achStatus: ""}
                    isRefunded={receipt.paymentsMade ? receipt.paymentsMade.isRefunded: ""}
                    refundedAmt={this.getRefundedAmt(receipt)}
                    lms={this.props.lms}
                    paymentStatus={receipt.paymentStatus}
                    amount={receipt.paymentAmount}
                    showReceiptDetails={this.props.showReceiptDetails}
                    principal={receipt.principal}
                    interest={receipt.interest}
                    lateFee={receipt.lateFee}
                    nsfFee={receipt.nsfFee}
                    principalBal={receipt.principalBal}
                    whiteLabel={this.props.whiteLabel}
                    {...this.setOptions(receipt)}
                  />
                </div>
              ))}
            </div>

            <div className="app-receipts__paginator">
              <Paginator
                forcePage={this.state.page}
                totalPages={this.state.totalPages}
                onPageChange={this.handleChangePage}
                whiteLabel={this.props.whiteLabel}
              />
            </div>
          </div>
        ) : (
          this.renderEmptyReceipts()
        )}

        {/**
         ** Delete schedule payment Modal
         **/}
        <Modal
          isOpen={this.state.isSendReceiptModalOpen}
          onClose={() => {
            this.toggleModal('SendReceipt');
          }}
        >
          <PaymentReceiptDelivery
            id={this.selectedReceipt}
            onSendReceipt={this.handleSendPaymentReceipt}
            whiteLabel={this.props.whiteLabel}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let country = null;
  if (state.auth.userData){
    country = state.auth.userData.tblState ? state.auth.userData.tblState.countryCode : 'US';
  }
  return {
    loanAccounts: state.app.loanAccounts,
    whiteLabel: state.whiteLabel,
    enableCardCanada: state.client.data.hideCardIfCa,
    countryCode: country,
    lms: state.client.data.lms,
    showReceiptDetails: state.client.data.showReceiptDetails
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      showLoading,
      hideLoading,
      sendReceipt,
      fetchLoanAccounts,
      fetchPaymentCards
    },
    dispatch
  );
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Receipts)
);
